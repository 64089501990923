(function () {
  'use strict';

  class CloneModuleSetCtrl {
    constructor($mdDialog, CloneModuleSetFlow) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.node = CloneModuleSetFlow.getNode();
      vm.clone = {
        module_document: {
          concat_type: 'suffix'
        }
      };
      vm.isClone = false;
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    resourceHandler() {
      const vm = this;
      if (vm.isClone) {
        vm.clone.resource = {
          concat_type: 'suffix'
        };
      } else {
        delete vm.clone.resource;
      }
    }
    hide(data) {
      let vm = this;
      vm.$mdDialog.hide(data);
    }
    submit(form) {
      let vm = this;
      if (form.$valid) {
        vm.hide(vm.clone);
      }
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:CloneModuleSetCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('CloneModuleSetCtrl', CloneModuleSetCtrl);
}());
